<template>
  <v-container class="ma-0 pa-0">
    <span class="label deduction">{{ label }}: {{ formatted(value) }}</span>
    <v-btn-toggle
      v-model="val"
      mandatory
      light
    >
      <v-btn
        v-for="v in values"
        :key="v"
        class="v-btn--flat"
        small
        :value="v"
      >
        {{ formatted(v) }}
      </v-btn>
    </v-btn-toggle>
  </v-container>
</template>

<script>
export default {
  name: 'punktewaehler',

  props: {
    value: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      requred: true
    },
    values: {
      type: Array,
      default: () => ([0, 1, 2, 3, 4, 5, 6, 7, 8])
    }
  },

  computed: {
    type () {
      if (!this.values.find(v => Math.floor(v) !== v)) return 'int_0'
      return 'float1_0'
    },
    val: {
      get () { return this.get() },
      set (val) { return this.set(val) }
    }
  },

  methods: {
    formatted (v) {
      return this.$options.filters[this.type](v)
    },
    get () {
      return this.value
    },
    set (val) {
      this.$emit('input', parseFloat(val))
    }
  }
}
</script>

<style scoped>
.label {
  display: inline-block;
  width: 50px;
}

.label.deduction {
  display: block;
  width: 100%;
}
</style>
